import React from 'react';
import './Message.css';

const Message = (props) => {
    const messages = props.checkpoints;
    let array = [];

    if(messages.tag === 'OutForDelivery')
        array = (
            <div className='OutForDelivery'>
                Out For <br />
                Delivery
            </div>
        );
    else if(messages.tag === 'Delivered')
        array = (
            <div className='Delivered'>
                Delivered
            </div>
        );
    else if(messages.tag === 'InTransit')
        array = (
            <div className='InTransit'>
                In <br />
                Transit
            </div>
        );
    else if(messages.tag === 'FulfillmentProcessed')
        array = (
            <div className='FulfillmentProcessed'>
                FulfillmentProcessed
            </div>
        );
    else if(messages.tag === 'InfoReceived')
        array = (
            <div className='InfoReceived'>
                Info <br />
                Received
            </div>
        );
    else if(messages.tag === 'Exception')
        array = (
            <div className='Exception'>
                Exception
            </div>
        );
    else if(messages.tag === 'AttemptFail')
        array = (
            <div className='AttemptFail'>
                AttemptFail
            </div>
        );
    else if(messages.tag === 'Pending')
        array = (
            <div className='Pending'>
                Pending
            </div>
        );
    
    return(
        <div className="checkpoint">
            <div>
                <div className="checkpoint_time">{messages.time}</div>
            </div>
            <div className='table'>
                <div className='message_table'>
                    {array}
                </div>
            </div>
            <div>
                <div className="checkpoint_message">{messages.message}</div>
                <div className="checkpoint_location">{messages.location}</div>
            </div>
        </div>
    );
}

export default Message;