import React from 'react';
import ReactDOM from 'react-dom';
// import Track from './Track';
import App from '../src/components/_App/App';
import './index.css';

// ReactDOM.render(
//     <div>
//         <Track/>
//     </div>
//     , document.getElementById('root'));
ReactDOM.render(<App />, document.getElementById('root'));
