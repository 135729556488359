import React from 'react';
import Message from '../Message/Message';
import './Checkpoints.css';
import { CSSTransitionGroup } from 'react-transition-group';

const Checkpoints = (props) => {
    let simplify = props.checkpoint_simplify;
    let data = props.checkpoints;
    let array = [];

    if(data.length === 0)
        return <div></div>;
    else if(simplify === false) {
        let size = data.length;
        let latest_date = data[size-1].date;
        array.push(<div className="checkpoints_date" key="checkpoints_date">{latest_date}</div>);
        for(let i = size - 1; i >= 0; i--){
            if( data[i].date !== latest_date){
                array.push(<div className="checkpoints_date" key={`checkpoints_date${i}`}>{data[i].date}</div>);
                latest_date = data[i].date;
            }
            array.push(<Message checkpoints={data[i]} key={`message${i}`}/>)
        }

        return (
            <div>
                <CSSTransitionGroup
                    transitionName="example"
                    transitionAppear={true}
                    transitionAppearTimeout={500}
                    transitionEnterTimeout={500}
                    transitionLeaveTimeout={300}
                >
                    <div className="checkpoints_detail">{array}</div>
                </CSSTransitionGroup>
            </div>
        )
    }
    else {
        let size = data.length;

        if(data[size-1].tag === 'OutForDelivery')
            array.push(
                <div className="OutForDelivery" key="OutForDelivery">
                    Out For <br />
                    Delivery
                </div>
            );
        else if(data[size-1].tag === 'Delivered')
            array.push(
                <div className="Delivered" key="Delivered">
                    Delivered
                </div>
            );
        else if(data[size-1].tag === 'InTransit')
            array.push(
                <div className="InTransit" key="InTransit">
                    In <br />
                    Transit
                </div>
            );
        else if(data[size-1].tag === 'InfoReceived')
            array.push(
                <div className="InfoReceived" key="InfoReceived">
                    Info <br />
                    Received
                </div>
            );
        else if(data[size-1].tag === 'Exception')
            array.push(
                <div className="Exception" key="Exception">
                    Exception
                </div>
            );
        else if(data[size-1].tag === 'AttemptFail')
            array.push(
                <div className="AttemptFail" key="AttemptFail">
                    AttemptFail
                </div>
            );
        else if(data[size-1].tag === 'Pending')
            array.push(
                <div className="Pending" key="Pending">
                    Pending
                </div>
            );

        return (
            <CSSTransitionGroup
                transitionName="example"
                transitionAppear={true}
                transitionAppearTimeout={500}
                transitionEnterTimeout={500}
                transitionLeaveTimeout={300}
            >
                <div className="checkpoint">
                    <div>
                        <div className="checkpoint_date">{data[size-1].date}</div>
                        <div className="checkpoint_time">{data[size-1].time}</div>
                    </div>
                    <div className='table'>
                        <div className='message_table'>
                            {array}
                        </div>
                    </div>
                    <div>
                        <div className="checkpoint_message">{data[size-1].message}</div>
                        <div className="checkpoint_location">{data[size-1].location}</div>
                    </div>
                </div>
            </CSSTransitionGroup>
        );
    }
}

export default Checkpoints;