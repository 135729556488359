import React, { useState } from 'react';
import Checkpoints from '../Checkpoint/Checkpoints';

const TrackingOutlines = (props) => {
    const [checkpoint_simplify, setCheckpoint_simplify] = useState(false);

    const SimplifyClick = () => {
        setCheckpoint_simplify(!checkpoint_simplify);
    }

    let response = props.response;

    return(
        <div className="tracking_outline">
            <span className="tracking_title">
            <div className="icon" onClick={SimplifyClick}>
                <i className={checkpoint_simplify ? "arrow right" : "arrow down"}></i>
            </div>
            <div className="tracking_info">
                <div className="tracking_info_label">OLS BBCode/Parcel ID</div>
                <div className="tracking_info_number">
                    {response.data.data.ols_key}
                </div>
                <div>
                    <div className="tracking_info_label">Sales Record Number</div>
                    <div className="tracking_info_number">{response.data.data.sales_record_number}</div>
                </div>
                <div>
                    <div className="tracking_info_label">Tracking Number</div>
                    <div className="tracking_info_number">{response.data.data.tracking_number}</div>
                </div>
            </div>
            </span>
            <Checkpoints checkpoint_simplify={checkpoint_simplify} checkpoints={response.data.data.checkpoints}/>
        </div>
    )
}

export default TrackingOutlines;