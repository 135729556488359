import React, { useState, useRef } from 'react';
import Input  from "../Input/Input";
import Search from "../Search/Search";
import './Track.css';

const Track = () => {
    const [ input, setInput ] = useState([]);
    const [ auth, setAuth ] = useState(false);
    const inputRef = useRef(null);

    const _addInput = (val) => {
        let newInput = [];
        newInput.push({
            input: val,
        });
        setInput(newInput);
        setAuth(true);
    }

    const _authfalse = (val) => {
        let newInput = [];
        newInput.push({
            input: val,
        });
        setInput(newInput);
        setAuth(false);
    }

    window.addEventListener('message', e => {
        if (e.origin !== process.env.REACT_APP_ERP_VIEW_URL && e.origin !== process.env.REACT_APP_ERP_CN_VIEW_URL) return
        else {
            let newInput = [];
            newInput.push(
                {
                    input: e.data.tracking_number,
                });
            setInput(newInput);
            setAuth(true);
        }
    }, false)

    let component = null;
    if (auth === false) { //initial
        component =
            <div>
                <div className="area_search">
                    <div className="title_search">Search Trackings</div>
                    <p></p>
                    <Input addInput={_addInput} input={input} ref={inputRef}/>
                </div>
            </div>;
    } else {
        component =
            <div>
                <div className="area_display">
                    <Search input={input} authfalse={_authfalse}/>
                </div>
            </div>
    }

    return(
        <div id="app">
            <div className="logo">
                <img src="Continental_logo.jpg" alt="Continental" />
            </div>
            <div className="textarea_search">
                {component}
            </div>
        </div>
    )
}

export default Track;
