import React from "react"
import './Search.css';
import axios from 'axios';
import TrackingOutlines from '../TrackingOutlines/TrackingOutlines';
import { Get, AxiosProvider } from 'react-axios';
import { CSSTransitionGroup } from 'react-transition-group';

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    timeout: 30000,
    headers: {'Api-Token': process.env.REACT_APP_API_TOKEN}
});

const test_url = '/internal/trackings/';

const Search = (props) => {
    const _BackClick = () => {
        const Input = props.input[0].input;
        props.authfalse(Input); //將 value 傳入父元件的 addInput
    }

    let track = props.input[0].input;
    track = track.trim();
    let track_array = track.split('\n');
    let size = track_array['length'];
    let array = [];

    for(let i=0; i < size; i++){
        let component_back_to_index = null;
        let component_back_to_index_error = null;

        if (i===0){
            component_back_to_index = 
            <div className="button_back_to_index">
                <span className="arrow-left" onClick={_BackClick}>&larr; Back to the index</span>
            </div>;
            component_back_to_index_error = 
            <div className="button_back_to_index_error">
                <span className="arrow-left" onClick={_BackClick}>&larr; Back to the index</span>
            </div>
        }

        let url = test_url + track_array[i];
        array.push(
            <div key={i}>
                <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"></link>
                <AxiosProvider instance={axiosInstance}>
                    <Get url={url}>
                        {(error, response, isLoading) => {
                            if(error)
                                return (
                                    <div>
                                        {component_back_to_index_error}
                                        <CSSTransitionGroup 
                                            transitionName="error" 
                                            transitionAppear={true}
                                            transitionAppearTimeout={500}
                                            transitionEnterTimeout={500}
                                            transitionLeaveTimeout={300}
                                        >
                                            <div className="error_message">Something bad happened: {error.message}</div>
                                        </CSSTransitionGroup>
                                    </div>
                                );
                            else if(isLoading && i===0)
                                return (<img className="gif" alt="" src="processing.gif"></img>)
                            else if(response !== null) {
                                if(response.data.status === '400')
                                    return (
                                        <div>
                                            {component_back_to_index_error}
                                            <CSSTransitionGroup 
                                                transitionAppear={true}
                                                transitionAppearTimeout={500}
                                                transitionEnterTimeout={500}
                                                transitionLeaveTimeout={300}
                                            >
                                                <div className="error_message">
                                                    {response.data.error[0].messages}
                                                </div>
                                            </CSSTransitionGroup>
                                        </div>
                                    );
                                else
                                    return (
                                        <div>
                                            {component_back_to_index}
                                            <TrackingOutlines response={response}/>
                                        </div>
                                    );
                            }
                            return <div></div>
                        }}
                    </Get>
                </AxiosProvider>
            </div>
        );
    }

    return(
        <div>{array}</div>
    )
}

export default Search;