import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import Track from '../Track/Track';

require('dotenv').config();

const App = () => {
    return(
        <Router>
            <Route path="/" exact component={ Track } />
        </Router>
    )
}

export default App;